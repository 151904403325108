import Vue from "vue";
import VueRouter, { RouteConfig, Route } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/unsubscribe/success",
    name: "NotificationStopSuccess",
    component: () => import("../views/NotificationStopSuccess.vue"),
  },
  {
    path: "/unsubscribe",
    name: "NotificationStop",
    component: () => import("../views/NotificationStop.vue"),
  },
  {
    path: "/sent-register",
    name: "SentRegister",
    component: () => import("../views/SentRegister.vue"),
  },
  {
    path: "/sent-register/success",
    name: "SentRegisterSuccess",
    component: () => import("../views/SentRegisterSuccess.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue"),
  },
  {
    path: "/register/success",
    name: "RegisterSuccess",
    component: () => import("../views/RegisterSuccess.vue"),
  },
  {
    path: "/course-select",
    name: "CourseSelect",
    component: () => import("../views/CourseSelect.vue"),
  },
  {
    path: "/date-select",
    name: "DateSelect",
    component: () => import("../views/DateSelect.vue"),
  },
  {
    path: "/hospital-reservation",
    name: "HospitalReservation",
    component: () => import("../views/HospitalReservation.vue"),
  },
  {
    path: "/hospital-reservation/confirm",
    name: "ReservationConfirm",
    component: () => import("../views/ReservationConfirm.vue"),
  },
  {
    path: "/hospital-reservation/success",
    name: "ReservationSuccess",
    component: () => import("../views/ReservationSuccess.vue"),
  },
  {
    path: "/mypage",
    name: "MyPage",
    component: () => import("../views/MyPage.vue"),
  },
  {
    path: "/mypage/edit",
    name: "MyPageEdit",
    component: () => import("../views/MyPageEdit.vue"),
  },
  {
    path: "/mypage/reservation-edit/:id",
    name: "MyPageReservationEdit",
    component: () => import("../views/MyPageReservationEdit.vue"),
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () => import("../views/ChangePassword.vue"),
  },
  {
    path: "/change-password/complete",
    name: "ChangePasswordComplete",
    component: () => import("../views/ChangePasswordComplete.vue"),
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("../views/ResetPassword.vue"),
  },
  {
    path: "/reset-password-send",
    name: "ResetPasswordSend",
    component: () => import("../views/ResetPasswordSend.vue"),
  },
  {
    path: "/reset-password-send/confirm",
    name: "ResetPasswordSendConfirmed",
    component: () => import("../views/ResetPasswordSendConfirmed.vue"),
  },
  {
    path: "/reset-password/complete",
    name: "ResetPasswordComplete",
    component: () => import("../views/ResetPasswordComplete.vue"),
  },
  {
    path: "/withdrawn",
    name: "Withdrawn",
    component: () => import("../views/Withdrawn.vue"),
  },
  {
    path: "/sitemap",
    name: "Sitemap",
    component: () => import("../views/Sitemap.vue"),
    beforeEnter: (to, from, next) => {
      if (process.env.NODE_ENV !== "production") {
        // allow navigation
        next();
      } else {
        // redirect somewhere else
        next(false);
      }
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line
  scrollBehavior(to: Route, from: Route, savedPosition: any) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

export default router;
