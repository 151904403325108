import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { ValidationProvider, ValidationObserver, extend, localize } from "vee-validate";
import VModal from "vue-js-modal";
import ja from "@/assets/ja.json";
import { required, email, integer, regex, confirmed, max, numeric, length } from "vee-validate/dist/rules";
import DatetimePicker from "vuetify-datetime-picker";
import moment from "moment";
import VueGtag from "vue-gtag";
import VCalendar from "v-calendar";

import { PhoneNumberUtil } from "google-libphonenumber";

require("@/assets/styles/index.sass");

Vue.config.productionTip = false;

localize("ja", ja);
Vue.component("ValidationProcider", ValidationProvider);
extend("required", required);
extend("integer", integer);
extend("length", length);
extend("email", email);
extend("regex", regex);
extend("confirmed", confirmed);
extend("max", max);
extend("numeric", numeric);
extend("hiragana", {
  validate: (v) => /^[\u30A1-\u30FC]+$/.test(v),
  message: "カタカナで入力してください",
});
extend("jis", {
  validate: (value) => {
    return containsOnlyOneByteCharacters(value);
  },
  message: "利用できない文字が含まれます",
});

function containsOnlyOneByteCharacters(str: string) {
  for (let i = 0; i < str.length; i++) {
    if (0xd800 <= str.charCodeAt(i) && str.charCodeAt(i) <= 0xdbff) {
      // 上位サロゲートが見つかった場合
      if (i + 1 < str.length && 0xdc00 <= str.charCodeAt(i + 1) && str.charCodeAt(i + 1) <= 0xdfff) {
        // 対応する下位サロゲートも存在する場合
        return false; // 4バイト文字が見つかった
      }
    }
  }
  return true; // 4バイト文字は見つからなかった
}

extend("anicomEmail", {
  validate: (v) =>
    /^[_a-zA-Z0-9!#$%&'*+-~/\^|{}]+[._a-zA-Z0-9!#$%&'*+-~/\^|{}]*@(([A-Za-z0-9-])+.)+[A-Za-z-]+$/.test(v),
  message: "利用できない文字が入っています",
});
extend("check0", {
  validate: (v) => new RegExp("^0").test(v),
  message: "電話番号1は0から入力してください",
});
extend("passwordLength", {
  validate: (v) => {
    //8文字以上20文字以下
    return v.length >= 8 && v.length <= 20;
  },
  message: "パスワードは8文字以上20文字以内で入力してください",
});
extend("phone1", {
  params: ["number2", "number3"],
  validate: (v, args: any) => {
    if (!v && !args.number2 && !args.number3) {
      return true;
    }
    if (!v || !args.number2 || !args.number3) {
      return false;
    }
    const tel = v + args.number2 + args.number3;
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      const phoneNumber = phoneUtil.parseAndKeepRawInput(tel, "JP");
      return phoneUtil.isValidNumber(phoneNumber);
    } catch (error) {
      return false;
    }
  },
  message: "正しい形式で入力してください",
});
extend("phone2", {
  params: ["number1", "number3"],
  validate: (v, args: any) => {
    if (!v && !args.number1 && !args.number3) {
      return true;
    }
    if (!v || !args.number1 || !args.number3) {
      return false;
    }
    const tel = args.number1 + v + args.number3;
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      const phoneNumber = phoneUtil.parseAndKeepRawInput(tel, "JP");
      return phoneUtil.isValidNumber(phoneNumber);
    } catch (error) {
      return false;
    }
  },
  message: "正しい形式で入力してください",
});
extend("phone3", {
  params: ["number1", "number2"],
  validate: (v, args: any) => {
    if (!v || !args.number1 || !args.number2) {
      return false;
    }
    const tel = args.number1 + args.number2 + v;
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      const phoneNumber = phoneUtil.parseAndKeepRawInput(tel, "JP");
      return phoneUtil.isValidNumber(phoneNumber);
    } catch (error) {
      return false;
    }
  },
  message: "正しい形式で入力してください",
});
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.use(VModal, { componentName: "VModal" });
Vue.use(DatetimePicker);
Vue.use(VCalendar, { componentPrefix: "vc" });

moment.locale("ja", { weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"] });

if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "staging") {
  Vue.use(
    VueGtag,
    {
      config: { id: process.env.VUE_APP_GTAG },
    },
    router
  );
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
